import React from 'react';
import Banner from './Banner';
import HomeoneService from './HomeoneService';
import TeamPrinciples from './TeamPrinciples';
import JoinUs from '../../common/JoinUs';

function HomePage() {
  return (
    <>
      <Banner />
      <TeamPrinciples />
      <HomeoneService />
      <JoinUs padding="pb-120" />
    </>
  );
}

export default HomePage;
