import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import WOW from 'wowjs';
import logo from '../../assets/images/logo/logo1.png';

function Header() {
  // const [state, dispatch] = useReducer(reducer, initialState);
  const currentRoute = useLocation().pathname;

  // Sticky Navbar
  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
    window.addEventListener('scroll', isSticky);
    return () => {
      window.removeEventListener('scroll', isSticky);
    };
  });
  const isSticky = () => {
    const header = document.querySelector('.header-section');
    const menuscrollTop = window.scrollY;
    menuscrollTop >= 20
      ? header.classList.add('sticky')
      : header.classList.remove('sticky');
  };

  /*----for single sidebar event use one state-------*/
  const [sidebar, setSidebar] = useState(false);
  const [sidebarleft, setSidebarleft] = useState(false);
  const showSidebar = () => {
    if (sidebar === false || sidebar === 0) {
      setSidebar(1);
    } else {
      setSidebar(false);
    }
  };
  const scrollTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });
  const showSidebarleft = () => {
    if (sidebarleft === false || sidebarleft === 0) {
      setSidebarleft(1);
    } else {
      setSidebarleft(false);
    }
  };

  return (
    <>
      <div
        className={
          sidebarleft === 1
            ? 'menu-toggle-btn-full-shape show-sidebar'
            : 'menu-toggle-btn-full-shape'
        }
      >
        <div className="menu-toggle-wrap bg-white">
          <div className="sidebar-top-area">
            <div onClick={showSidebarleft} className="cross-icon">
              <i className="bx bx-x" />
            </div>
            <div className="sidebar-logo">
              <Link onClick={scrollTop} to={'/'}>
                <img
                  src={process.env.PUBLIC_URL + '/images/icon/logo.png'}
                  alt="images"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <header className="header-section">
        <div className="container-fluid">
          <div className="header-area">
            <div className="header-logo">
              <Link onClick={scrollTop} to={`${process.env.PUBLIC_URL}/`}>
                <img src={logo} width={130} alt="devemi" />
              </Link>
            </div>
            <div className="mobile-menu-btn text-white d-lg-none d-block">
              <i onClick={showSidebar} className="bi bi-list" />
            </div>
            <div
              className={sidebar === 1 ? 'main-menu show-menu' : 'main-menu'}
            >
              <div className="mobile-logo-area d-lg-none d-block">
                <div className="mobile-logo-wrap d-flex justify-content-between align-items-center">
                  <img src={logo} width={200} height={40} alt="images" />
                  <div className="menu-close-btn" onClick={showSidebar}>
                    <i className="bi bi-x-lg" />
                  </div>
                </div>
              </div>
              <ul className="menu-list" onClick={showSidebar}>
                <li
                  className={
                    currentRoute === '/about-us'
                      ? 'menu-item active'
                      : 'menu-item'
                  }
                >
                  <Link
                    onClick={scrollTop}
                    to={`${process.env.PUBLIC_URL}/about-us`}
                    className="menu-link"
                  >
                    ABOUT US
                  </Link>
                </li>
                <li
                  className={
                    currentRoute === '/service'
                      ? 'menu-item active'
                      : 'menu-item'
                  }
                >
                  <Link
                    onClick={scrollTop}
                    to={`${process.env.PUBLIC_URL}/service`}
                    className="menu-link"
                  >
                    Services
                  </Link>
                </li>
                <li
                  className={
                    currentRoute === '/blog' ? 'menu-item active' : 'menu-item'
                  }
                >
                  <Link
                    onClick={scrollTop}
                    to={`${process.env.PUBLIC_URL}/blog`}
                    className="menu-link"
                  >
                    blog
                  </Link>
                </li>
                <li
                  className={
                    currentRoute === '/faq' ? 'menu-item active' : 'menu-item'
                  }
                >
                  <Link
                    onClick={scrollTop}
                    to={`${process.env.PUBLIC_URL}/faq`}
                    className="menu-link"
                  >
                    FAQ
                  </Link>
                </li>
                <li
                  className={
                    currentRoute === '/contact-us'
                      ? 'menu-item active'
                      : 'menu-item'
                  }
                >
                  <Link
                    onClick={scrollTop}
                    to={`${process.env.PUBLIC_URL}/contact-us`}
                    className="menu-link"
                  >
                    CONTACT
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
