import React from 'react';
import { INITIAL_WOW_DELAY } from '../../constants/variables';
import { dataWowDelayHandler } from '../../utils/delayHandler';

function SectionTitle(props) {
  return (
    <>
      <div className="col-md-12 text-center">
        <div
          className="section-title wow animate fadeInDown"
          data-wow-duration={INITIAL_WOW_DELAY}
          data-wow-delay={dataWowDelayHandler(0.1)}
        >
          <h2>{props.title}</h2>
        </div>
      </div>
    </>
  );
}

export default SectionTitle;
