import React from 'react';

import ReactDOM from 'react-dom';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import Layout from './components/App';
import MainLayout from './components/layout/MainLayout';
import SimpleReactLightbox from 'simple-react-lightbox';
import './index.css';
import FaqPage from './components/page/faq/FaqPage';
import ErrorPage from './components/page/error/ErrorPage';
import BlogGridPage from './components/page/blogGrid/BlogGridPage';
import BlogDetailsPage from './components/page/blogDetails/BlogDetailsPage';
import ServicePage from './components/page/service/ServicePage';
import AboutPage from './components/page/about/AboutPage';
import ContactUsPage from './components/page/contactUs/ContactUsPage';

function Root() {
  return (
    <>
      <BrowserRouter basename="/">
        <Switch>
          <Redirect exact from="/" to="/home" />
          <Route exact path="/home" component={MainLayout} />
          <Layout>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/about-us`}
              component={AboutPage}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/contact-us`}
              component={ContactUsPage}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/faq`}
              component={FaqPage}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/blog`}
              component={BlogGridPage}
            />

            <Route
              path={`${process.env.PUBLIC_URL}/blog/:id`}
              render={(props) => <BlogDetailsPage {...props} />}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/service`}
              component={ServicePage}
            />
          </Layout>
          <Route component={ErrorPage} />
        </Switch>
      </BrowserRouter>
    </>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <SimpleReactLightbox>
      <Root />
    </SimpleReactLightbox>
  </React.StrictMode>,
  document.getElementById('root'),
);
