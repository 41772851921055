import { INITIAL_WOW_DELAY } from '../../constants/variables';
import { dataWowDelayHandler } from '../../utils/delayHandler';
import { Link } from 'react-router-dom';
import React from 'react';
import { teamPrinciples } from '../../constants';

const AboutDetailsLeft = () => {
  return (
    <div className="col-lg-5 col-md-5">
      <div
        className="hire-card wow animate fadeInDown"
        data-wow-duration={INITIAL_WOW_DELAY}
        data-wow-delay={dataWowDelayHandler(0.1)}
      >
        <h3>DO YOU HAVE A PROJECT IN YOUR MIND</h3>
        <div className="eg-btn hire-btn hover-btn">
          <span>
            <Link to={`${process.env.PUBLIC_URL}/contact-us`}>
              Contact Us Now
              <img
                src={process.env.PUBLIC_URL + '/images/icon/arrow-dark.svg'}
                alt="images"
              />
            </Link>
          </span>
        </div>
      </div>
      <div className="service-details-area">
        <div className="service-details-content">
          {teamPrinciples.slice(0, 4).map((teamPrinciple, index) => {
            return (
              <div key={index}>
                <h4>{teamPrinciple.title}</h4>
                <p className="para">{teamPrinciple.description}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AboutDetailsLeft;
