import React from 'react';
import { Link } from 'react-router-dom';
import { INITIAL_WOW_DELAY } from '../../../constants/variables';
import { dataWowDelayHandler } from '../../../utils/delayHandler';
import { contactInfo } from '../../../constants';
import emailjs from '@emailjs/browser';
import ContactForm from './ContactForm';
import EmailSentComponent from './EmailSentComponent';
import { useState } from 'react';
import EmailErrorComponent from './EmailErrorComponent';

const serviceId = process.env.REACT_APP_EMAIL_SERVICE_ID;
const templateId = process.env.REACT_APP_EMAIL_TEMPLATE_ID;
const emailPublicKey = process.env.REACT_APP_EMAIL_PUBLIC_KEY;

function ContactWrap() {
  const [emailSent, setEmailSent] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const scrolltop = () => window.scrollTo({ top: 0, behavior: 'smooth' });
  const { city, country, zipCode, street, phone, email } = contactInfo;

  const sendEmail = async (emailData) => {
    try {
      await emailjs.send(serviceId, templateId, emailData, emailPublicKey);
      setEmailSent(true);
    } catch (e) {
      setEmailError(true);
    }
  };

  const renderFormSection = () => {
    if (!emailSent)
      return <ContactForm sendEmail={(emailData) => sendEmail(emailData)} />;
    return <EmailSentComponent />;
  };

  return (
    <>
      <div className="contact-section pt-120 pb-120">
        <div className="container">
          <div className="row pb-120 g-4 d-flex justify-content-center">
            <div className="col-lg-4 col-md-6 col-sm-8">
              <div className="eg-card-contact contact-signle hover-btn">
                <span></span>
                <div className="icon">
                  <i className="bi bi-geo-alt"></i>
                </div>
                <div className="text">
                  <h3>Location</h3>
                  <h4>{`${zipCode}, ${street}`}</h4>
                  <h5>{`${city}, ${country}`}</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-8">
              <div className="eg-card-contact contact-signle hover-btn">
                <span></span>
                <div className="icon">
                  <i className="bx bx-phone-call"></i>
                </div>
                <div className="text">
                  <h3>Phone</h3>
                  <h4>{phone}</h4>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-8 ">
              <div className="eg-card-contact contact-signle hover-btn">
                <span></span>
                <div className="icon">
                  <i className="bx bx-envelope"></i>
                </div>
                <div className="text">
                  <h3>Email</h3>
                  <a href={`mailto:${email}`}>
                    <h4>{email}</h4>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-lg-end justify-content-center">
            <div className="col-md-8 text-center">
              <div
                className="section-title wow animate fadeInDown"
                data-wow-duration={INITIAL_WOW_DELAY}
                data-wow-delay={dataWowDelayHandler(0.1)}
              >
                <h2>Get In Touch.</h2>
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center">
            <div className="col-lg-4 col-md-8 col-sm-8">
              <div
                className="section-card contact-section-card wow animate fadeInUp"
                data-wow-duration={INITIAL_WOW_DELAY}
                data-wow-delay={dataWowDelayHandler(0.1)}
              >
                <h2>Innovative Solutions</h2>
                <p>
                  Let's work together to turn your project dreams into reality
                  and create something truly extraordinary!
                </p>
                <Link
                  onClick={scrolltop}
                  to={`${process.env.PUBLIC_URL}/about-us`}
                  className="eg-btn hover-btn sectoin-card-btn"
                >
                  <span>
                    See our values
                    <img
                      src={
                        process.env.PUBLIC_URL + '/images/icon/arrow-servc.svg'
                      }
                      alt="icons"
                    />
                  </span>
                </Link>
              </div>
            </div>
            {emailError ? <EmailErrorComponent /> : renderFormSection()}
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactWrap;
