import React from 'react';
import BlogGridWrap from './BlogGridWrap';

function BlogGridPage() {
  return (
    <>
      <BlogGridWrap />
    </>
  );
}

export default BlogGridPage;
