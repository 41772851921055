import React from 'react';
import RecentPostWidget from '../blogSidebar/RecentPostWidget';
import SocialLinkWidget from '../blogSidebar/SocialLinkWidget';
import BlogMainPost from './BlogMainPost';
import NextAndPrevPost from './NextAndPrevPost';

const BlogDetailsWrap = ({ currentPost }) => {
  return (
    <>
      <div className="blog-post-section pt-120 pb-120">
        <div className="container">
          <div className="row d-flex justify-content-center gy-5">
            <div className="col-lg-8 col-md-10 col-sm-10">
              <div className="blog-post-area">
                <BlogMainPost currentPost={currentPost} />
                <NextAndPrevPost currentPost={currentPost} />
              </div>
            </div>
            <div className="col-lg-4 col-md-10 col-sm-10">
              <div className="blog-left-sidebar">
                <RecentPostWidget currentPost={currentPost} />
                <SocialLinkWidget />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogDetailsWrap;
