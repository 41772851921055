import React from 'react';
import BlogCard from './BlogCard';
// import Pagination from '../../common/Pagination';
import { blogData } from '../../../constants/blogArticles';
import TitleHolder from '../../common/TitleHolder';

function BlogGridWrap() {
  return (
    <>
      <div className="blog-grid-section">
        <TitleHolder title="Blog" titleSide={'center'} />
        <div className="container">
          <div className="row d-flex justify-content-center g-4">
            {blogData.map((blog, index) => (
              <div key={index} className="col-lg-6 col-md-8 col-sm-10">
                <BlogCard
                  id={blog.id}
                  image={blog.image}
                  author={blog.author}
                  title={blog.title}
                />
              </div>
            ))}
          </div>
          {/*<Pagination />*/}
        </div>
      </div>
    </>
  );
}

export default BlogGridWrap;
