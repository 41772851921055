import React from 'react';
import { Link } from 'react-router-dom';
import { blogData } from '../../../constants/blogArticles';
const RecentPostWidget = ({ currentPost }) => {
  const recentPostsExceptCurrent = blogData.filter(
    (item) => item.id !== currentPost.id,
  );

  const sortedBlogPosts = recentPostsExceptCurrent.sort((a, b) => {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);
    return dateB - dateA;
  });

  const displayedLastFourPosts = sortedBlogPosts.slice(0, 4);

  return (
    <>
      <div className="widget">
        <h4 className="widget-title">Recent Post</h4>
        <ul className="m-0 p-0">
          {displayedLastFourPosts.map((post, index) => (
            <li className="recent-post-item" key={index}>
              <div className="post-image">
                <img src={process.env.PUBLIC_URL + post.image} alt="images" />
              </div>
              <div className="post-content">
                <p className="date">{post.date}</p>
                <h6>
                  <Link to={`${process.env.PUBLIC_URL}/blog/${post.id}`}>
                    {post.title}
                  </Link>
                </h6>
              </div>
            </li>
          ))}
        </ul>
        <div></div>
      </div>
    </>
  );
};

export default RecentPostWidget;
