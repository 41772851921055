export const blogData = [
  {
    id: 1,
    title: 'Modern Serverless Philosophy',
    image: process.env.PUBLIC_URL + '/images/blog/modern-serverless-arch.png',
    author: 'Ionut Donca',
    details: 'Modern Serverless Philosophy',
    mainImg: process.env.PUBLIC_URL + '/images/blog/modern-serverless-arch.png',
    relatedTags: 'Architecture',
    date: 'December 13, 2023',
    post: {
      title: 'Modern Serverless Philosophy',
      firstParagraph:
        'The contemporary concept of serverless is commonly linked with Function as a Service\n' +
        '(FaaS), yet its definition has evolved into more of a philosophy than a universally agreed-\n' +
        'upon definition. When discussing serverless, the focus is often on efficiently delivering\n' +
        'business value by prioritizing the creation of business logic over coding the supporting\n' +
        'infrastructure for that logic. Embracing a serverless mindset involves actively seeking and\n' +
        'utilizing FaaS, managed services, and intelligent abstractions, resorting to custom solutions\n' +
        'only when no suitable existing service is available.\n' +
        'This approach is gaining traction among companies and developers who recognize the\n' +
        'impracticality of reinventing established solutions. The rise of the serverless philosophy has\n' +
        'led to a proliferation of services and tools from startups and cloud providers, aiming to\n' +
        'simplify the complexity of backend operations.\n' +
        'For an academic perspective on serverless, the 2019 paper "Cloud Programming Simplified:\n' +
        'A Berkeley View on Serverless Computing" by UC Berkeley offers insights. The paper\n' +
        'broadens the definition of serverless, highlighting that while cloud functions packaged as\n' +
        'FaaS represent its core, cloud platforms also provide specialized serverless frameworks\n' +
        'known as Backend as a Service (BaaS) offerings. BaaS encompasses managed services such\n' +
        'as databases (e.g., Firestore, Amazon DynamoDB), authentication services (e.g., Auth0,\n' +
        'Amazon Cognito), and artificial intelligence services (e.g., Amazon Rekognition, Amazon\n' +
        "Comprehend). Berkeley's redefinition underscores the evolving landscape as cloud providers\n" +
        'enhance their repertoire of managed services, categorizing them under the umbrella of\n' +
        'serverless.',
      secondTitle: 'Characteristics of a Serverless Application',
      secondParagraph:
        'Now that you have insight into the serverless philosophy, what are the defining features of a\n' +
        'serverless application? While definitions of serverless may differ, the industry generally\n' +
        'acknowledges certain traits and characteristics.\n' +
        'Serverless architectures typically enable the transfer of more operational duties to a cloud\n' +
        'provider or third party. When opting for Function as a Service (FaaS), your primary concern\n' +
        'should be the code within your function. Tasks such as server patching, updates,\n' +
        'maintenance, and upgrades are no longer under your purview. This aligns with the\n' +
        'fundamental goal of cloud computing and, by extension, serverless computing: minimizing\n' +
        'the time spent on infrastructure management and maximizing focus on feature development\n' +
        'and delivering business value.\n' +
        'Managed services often assume responsibility for delivering a well-defined set of features.\n' +
        'They align with the serverless paradigm by seamlessly scaling, eliminating the need for\n' +
        'server operations, ensuring uninterrupted uptime, and, significantly, minimizing the need for\n' +
        'extensive coding efforts.',
      thirdTitle: 'Benefits of a Serverless Architecture',
      thirdParagraph:
        "In today's landscape of application architecture, crucial decisions made early on significantly\n" +
        'impact the entire application life cycle, development teams, and the overarching success of a\n' +
        'company or organization. This book promotes the adoption of serverless technologies and\n' +
        'methodologies, outlining ways to leverage them effectively. But what exactly are the benefits\n' +
        'of constructing an application in this manner, and why is the serverless approach gaining\n' +
        'widespread popularity?\n' +
        'Serverless architectures inherently offer seamless scalability. The concern of accommodating\n' +
        'a sudden surge in popularity or rapid user onboarding is alleviated, as the cloud provider\n' +
        'autonomously handles scaling. Serverless functions process triggers in parallel, scaling\n' +
        'proportionally with the workload. This eliminates the need to fret over scaling servers and\n' +
        'databases, allowing developers to focus on application architecture.\n' +
        'Serverless architectures differ significantly in pricing models compared to traditional cloud-\n' +
        'based or on-premises infrastructures. Traditional approaches often involve paying for\n' +
        'computing resources, whether utilized or not, resulting in upfront costs for anticipated\n' +
        'workloads. In contrast, serverless technologies operate on a pay-as-you-go model, billing\n' +
        'based on actual resource consumption. This minimizes upfront infrastructure costs and allows\n' +
        'for cost-efficient scalability as adoption grows.\n' +
        'Reduced feature development tasks lead to increased developer velocity. Swift\n' +
        'implementation of typical application features, such as databases, authentication, storage, and\n' +
        'APIs, enables a rapid focus on core functionality and business logic. This, in turn, facilitates\n' +
        'faster time to market and decreased overall development time and costs.\n' +
        'Serverless architectures, by minimizing time spent on repetitive features, facilitate easier and\n' +
        'less risky experimentation. With reduced risks associated with trying out new ideas,\n' +
        'developers can more freely test and iterate on features. The increased developer velocity\n' +
        'supports efficient A/B testing of different ideas, allowing for quicker validation and\n' +
        'optimization.\n' +
        'Leveraging services from specialized providers ensures polished, secure, and stable solutions.\n' +
        'Service providers dedicated to specific core competencies, such as authentication services,\n' +
        'deliver a level of expertise and reliability that might be challenging to replicate in-house.\n' +
        'Additionally, these providers prioritize minimizing downtime, shouldering the responsibility\n' +
        'of building, deploying, and maintaining robust services.\n' +
        'Recognizing that code is a liability, serverless architectures emphasize delivering features\n' +
        'while minimizing the codebase. This reduction in overall complexity leads to fewer bugs,\n' +
        'smoother onboarding for new engineers, and less cognitive load for maintenance. Developers\n' +
        'can harness services without delving into intricate backend implementations, enabling feature\n' +
        'implementation with minimal or no backend code.',
    },
  },
  {
    id: 2,
    title: 'Microservices in NestJS',
    image: process.env.PUBLIC_URL + '/images/blog/microservices-in-nestjs1.png',
    author: 'Rares Oprea',
    details: 'Microservices in NestJS: A Paradigm Shift',
    mainImg:
      process.env.PUBLIC_URL + '/images/blog/microservices-in-nestjs.png',
    relatedTags: 'Architecture',
    date: 'January 6, 2024',
    post: {
      title: 'Microservices in NestJS: A Paradigm Shift',
      firstParagraph:
        'In the rapidly evolving landscape of web development, building scalable and maintainable applications is crucial. ' +
        'One approach gaining traction is microservices architecture, and when it comes to seamlessly implementing this architecture, ' +
        'NestJS stands out. NestJS, built on top of Node.js, provides a robust framework for building scalable server-side applications. ' +
        'What sets NestJS apart is its out-of-the-box support for microservices architecture, enabling developers to create modular ' +
        'and loosely coupled components. Microservices architecture involves breaking down a monolithic application into smaller, ' +
        'independent services that can be developed, deployed and scaled. ' +
        'NestJS simplifies the implementation of microservices by offering built-in support. ' +
        'NestJS supports common messaging patterns such as Request-Reply, Publish-Subscribe, and One-Way. ' +
        'This enables efficient communication between microservices in different scenarios.',
      secondTitle: 'Dependency Injection: The Backbone of NestJS',
      secondParagraph:
        'One of the standout features of NestJS is its powerful implementation of Dependency Injection (DI). Dependency Injection is a design pattern that promotes modular and maintainable code by allowing the injection of dependencies rather than hard-coding them.',
      thirdTitle: ' ',
      thirdParagraph:
        'In the following we will go through some key concepts of Dependency Injection in NestJS.\n' +
        'Providers: In NestJS, providers are classes annotated with the @Injectable() decorator. These classes encapsulate the logic and functionality of a particular module or service.\n' +
        '\n' +
        "Injectors: The injector is responsible for resolving and injecting dependencies into the components that require them. NestJS's injector works seamlessly with providers, making it easy to manage and organize dependencies.\n" +
        '\n' +
        "Scopes: NestJS supports different scopes for providers, including Singleton, Request, and Transient. This allows developers to control the lifecycle of dependencies based on the application's requirements.\n" +
        '\n' +
        'Additional Features of NestJS:\n' +
        'Decorators: NestJS leverages decorators extensively to define the structure of applications. Decorators like @Module, @Controller, and @Inject contribute to a clean and readable codebase.\n' +
        '\n' +
        'Middleware: NestJS middleware enables developers to process requests before they reach route handlers. This can be useful for tasks like authentication, logging, and error handling.\n' +
        '\n' +
        'Interceptors: Interceptors in NestJS allow developers to alter the behavior of the request-handling process, providing a powerful mechanism for cross-cutting concerns.',
      fourthParagraph:
        `In conclusion, NestJS empowers developers to build scalable, maintainable, and modular applications using microservices architecture. Its support for dependency injection and a host of other features makes it a compelling choice for those seeking a robust framework that aligns with modern development practices.\n` +
        "Whether you're diving into microservices for the first time or looking to enhance your existing NestJS skills, the combination of microservices architecture and dependency injection in NestJS opens up a world of possibilities for creating high-performance and scalable applications. Embrace the power of NestJS and unlock the full potential of your microservices-based projects. Happy coding!",
    },
  },
];
