import React from 'react';
import { Link } from 'react-router-dom';
import { dateConverter } from '../../../utils/dateConverter';

const BlogMainPost = ({ currentPost }) => {
  const { mainImg, relatedTags, author, date, post } = currentPost;
  const {
    title,
    secondTitle,
    thirdTitle,
    firstParagraph,
    secondParagraph,
    thirdParagraph,
    fourthParagraph,
    fancyParagraph,
    fancyParagraphAuthor,
  } = post;

  return (
    <>
      <div className="blog-post-img">
        <img src={mainImg} alt={relatedTags} />
      </div>
      <ul className="blog-post-meta d-flex justify-content-start flex-wrap">
        <li>
          <Link
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            to={'#'}
          >
            <i className="bi bi-person-fill" />
            <span>By {author}</span>
          </Link>
        </li>
        <li>
          <Link to={'#'}>
            <i className="bi bi-clock" />
            <span>{dateConverter(date)}</span>
          </Link>
        </li>
      </ul>
      <h2 className="post-title">{title}</h2>
      <p className="para-style mb-30">{firstParagraph}</p>
      {secondTitle ? <h2 className="post-title">{secondTitle}</h2> : null}
      <p className="para-style">{secondParagraph}</p>
      {thirdTitle ? <h2 className="post-title">{thirdTitle}</h2> : null}
      {fancyParagraph ? (
        <div className="blockquote">
          <h5>{fancyParagraph}</h5>
          <h4>-{fancyParagraphAuthor}-</h4>
          <img
            src={process.env.PUBLIC_URL + '/images/icon/quote-icon.svg'}
            className="quote-icon"
            alt="images"
          />
        </div>
      ) : null}
      <p className="para-style mb-30">{thirdParagraph}</p>
      <p className="para-style">{fourthParagraph}</p>
    </>
  );
};

export default BlogMainPost;
