import React from 'react';
import ServiceProgressArea from './ServiceProgressArea';
import TitleHolder from '../../common/TitleHolder';

function ServiceWrap() {
  return (
    <>
      <div className="service-section pt-60">
        <div className="container">
          <TitleHolder />
          {/*<Cards/>*/}
          <ServiceProgressArea />
        </div>
      </div>
    </>
  );
}

export default ServiceWrap;
