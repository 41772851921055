import React from 'react';
import { Link } from 'react-router-dom';
import { INITIAL_WOW_DELAY } from '../../../constants/variables';
import { dataWowDelayHandler } from '../../../utils/delayHandler';
import { team } from '../../../constants';

function TeamArea() {
  const scrollTop = (member) => {
    const linkedinProfileUrl = `https://www.linkedin.com/in/${member.linkedin}`;
    window.open(linkedinProfileUrl, '_blank');
  };

  return (
    <>
      <div className="team-section pt-120">
        <div className="container">
          <div className="row d-flex justify-content-lg-start justify-content-center">
            <div className="col-md-8 text-center">
              <div
                className="section-title wow animate fadeInDown"
                data-wow-duration={INITIAL_WOW_DELAY}
                data-wow-delay={dataWowDelayHandler(0.1)}
              >
                <h2>Our Team</h2>
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center g-4">
            {team.map((member, index) => (
              <div
                key={index}
                className={`col-lg-4 col-md-6 col-sm-8 order-1 wow animate fadeInDown`}
                data-wow-duration={INITIAL_WOW_DELAY}
                data-wow-delay={dataWowDelayHandler(0.1 + 0.1 * index)}
              >
                <div className="team-item text-center">
                  <img src={member.image} alt="images" />
                  <div className="team-overlay">
                    <div className="overlay-content hover-btn">
                      <span />
                      <h4>
                        <Link
                          onClick={() => scrollTop(member)}
                          target="_blank" // Use "target" instead of "blank"
                        >
                          {member.name}
                        </Link>
                      </h4>
                      <p>{member.title}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default TeamArea;
