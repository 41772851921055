import React, { useState } from 'react';
import { contactInfo } from '../../constants';
import logo from '../../assets/images/logo/logo1.png';

function Footer() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });
  const { email } = contactInfo;

  return (
    <>
      <footer className="footer-section">
        <div className="container">
          <div className="footer-bottom-area">
            <div className="row align-items-center">
              <div className="col-lg-5 col-md-6 text-sm-start d-flex justify-content-between flex-grow-1">
                <a onClick={scrollTop} href={`${process.env.PUBLIC_URL}/`}>
                  <img src={logo} width={130} alt="devemi-footer" />
                </a>
                <div className="footer-right-address">
                  <ul className="address-list">
                    <li>
                      <a onClick={scrollTop} href={`mailto:${email}`}>
                        <span>Email: {email}</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <div className="scroll-btn show">
        <ScrollButton></ScrollButton>
      </div>
    </>
  );
}
const ScrollButton = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 800) {
      setVisible(true);
    } else if (scrolled <= 100) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };

  window.addEventListener('scroll', toggleVisible);

  return (
    <i
      className="bx bxs-up-arrow-circle"
      onClick={scrollToTop}
      style={{ display: visible ? 'inline' : 'none' }}
    ></i>
  );
};

export default Footer;
