import React from 'react';
import { Link } from 'react-router-dom';
import { INITIAL_WOW_DELAY } from '../../constants/variables';
import { dataWowDelayHandler } from '../../utils/delayHandler';

function JoinUs(props) {
  return (
    <>
      <div
        className={`${'join-us-section wow animate fadeInDown'} ${props.padding}`}
        data-wow-duration={INITIAL_WOW_DELAY}
        data-wow-delay={dataWowDelayHandler(0.1)}
      >
        <div className="container">
          <div className="join-us-area">
            <div className="join-us-content flex-md-nowrap flex-wrap">
              <h2> Unlock Your Digital Potential Today!</h2>
              <h5>
                Empower Your Growth, Ignite Your Success in the Digital Age.
              </h5>
            </div>
            <div className="eg-btn join-btn hover-btn">
              <span>
                <Link
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: 'smooth' })
                  }
                  to={`${process.env.PUBLIC_URL}/contact-us`}
                >
                  Contact Us
                  <img
                    src={process.env.PUBLIC_URL + '/images/icon/arrow-dark.svg'}
                    alt="images"
                  />
                </Link>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default JoinUs;
