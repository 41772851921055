import React from 'react';
import SwiperCore, {
  Autoplay,
  EffectFade,
  Navigation,
  Pagination,
} from 'swiper';
import 'swiper/css/autoplay';

import { Link } from 'react-router-dom';
import Typewriter from 'typewriter-effect';

SwiperCore.use([Navigation, Pagination, EffectFade, Autoplay]);

function Banner() {
  const phrases = [
    'Innovative Solutions!',
    'Exceptional Projects!',
    'Revolutionary Products!',
    'Remarkable Creations!',
    'Outstanding Innovations!',
    'Extraordinary Ventures!',
    'Inspiring Breakthroughs!',
    'Cutting-Edge Developments!',
    'High-impact Solutions!',
    'Unparalleled Results!',
    'Game-Changing Initiatives!',
  ];

  return (
    <>
      <div className="banner-section position-relative">
        <div className="swiper banner1">
          <div className="swiper-wrapper">
            <div className="swiper-slide position-relative">
              <div className="container-fluid">
                <div className="row align-items-center">
                  <div className="col-lg-5 col-md-7 d-md-block d-none py-2">
                    <div className="banner-img">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          '/images/banner/withAi/cover1.jpeg'
                        }
                        className="banner-img-left"
                        alt="images"
                      />
                      <img
                        className="top-right-dot"
                        src={
                          process.env.PUBLIC_URL +
                          '/images/banner/top-right-dot.svg'
                        }
                        alt="images"
                      />
                      <img
                        className="bottom-left-dot"
                        src={
                          process.env.PUBLIC_URL +
                          'images/banner/bottom-left-dot.svg'
                        }
                        alt="images"
                      />
                    </div>
                  </div>
                  <div className="col-lg-7 col-md-5 col-12">
                    <div className="banner-content mobile-bg1">
                      <h2>Welcome to DevEmi:</h2>
                      <div className="banner-message-small">
                        <h1>
                          <Typewriter
                            onInit={(typewriter) => {
                              typewriter
                                .changeDelay(50)
                                .typeString('Where Ideas Evolve into <br> ');

                              phrases.forEach((phrase, index) => {
                                typewriter
                                  .typeString(phrase)
                                  .pauseFor(4000)
                                  .changeDeleteSpeed(10)
                                  .deleteChars(phrase.length);

                                if (index !== phrases.length - 1) {
                                  typewriter.pauseFor(100);
                                }
                              });
                              typewriter.start();
                            }}
                            options={{
                              cursor: '|',
                              loop: true,
                            }}
                          />
                        </h1>
                      </div>

                      <p>
                        At the heart of every successful project lies a team of
                        skilled developers who are dedicated to its success. At
                        DevEmi, our team embodies this spirit, leveraging their
                        expertise and ingenuity to develop projects that
                        inspire, engage, and deliver results.
                      </p>
                      <Link
                        onClick={() =>
                          window.scrollTo({ top: 0, behavior: 'smooth' })
                        }
                        to={`${process.env.PUBLIC_URL}/contact-us`}
                        className="eg-btn banner-btn hover-btn"
                      >
                        <span>
                          Contact Manager
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              '/images/icon/btn-arrow.svg'
                            }
                            alt="images"
                          />
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Banner;
