import React from 'react';
// import Breadcrumb from '../../common/Breadcrumb';
// import Sponsor from '../../common/Sponsor';
// import JoinUs from '../../common/JoinUs';
// import PricingCard from './PricingCard';
import ServiceWrap from './ServiceWrap';
import ServiceDetailsWrap from '../serviceDetails/ServiceDetailsWrap';
import JoinUs from '../../common/JoinUs';

function ServicePage() {
  return (
    <>
      {/*<Breadcrumb name={OUR_SERVICES} />*/}
      <ServiceDetailsWrap />
      <ServiceWrap />
      {/*<PricingCard />*/}
      <JoinUs padding="pb-5 pt-5" />
      {/*<Sponsor />*/}
    </>
  );
}

export default ServicePage;
