import React from 'react';
import JoinUs from '../../common/JoinUs';
import TeamArea from '../home/TeamArea';
import TeamPrinciplesDetailed from '../serviceDetails/TeamPrinciplesDetailed';

function AboutPage() {
  return (
    <>
      <TeamPrinciplesDetailed />
      <TeamArea />
      <JoinUs padding="pb-5 pt-5" />
    </>
  );
}

export default AboutPage;
