import { INITIAL_WOW_DELAY } from '../../constants/variables';
import { dataWowDelayHandler } from '../../utils/delayHandler';
import React from 'react';

const TitleHolder = ({ title, titleSide }) => {
  return (
    <div
      className={`row d-flex justify-content-lg-${
        titleSide || 'start'
      } justify-content-center`}
    >
      <div className="col-md-8 text-center">
        <div
          className="section-title wow animate fadeInDown"
          data-wow-duration={INITIAL_WOW_DELAY}
          data-wow-delay={dataWowDelayHandler(0.1)}
        >
          <h2>{title}</h2>
        </div>
      </div>
    </div>
  );
};

export default TitleHolder;
