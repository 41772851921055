import React from 'react';
import JoinUs from '../../common/JoinUs';
import FaqWrap from './FaqWrap';

function FaqPage() {
  return (
    <>
      <FaqWrap />
      <JoinUs padding="pb-5 pt-5" />
    </>
  );
}

export default FaqPage;
