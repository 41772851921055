import React from 'react';
import { Link } from 'react-router-dom';
import { blogData } from '../../../constants/blogArticles';

const NextAndPrevPost = ({ currentPost }) => {
  const { id } = currentPost;
  const postIndex = blogData.findIndex((post) => post.id === +id);

  const isLastPost = postIndex === blogData.length - 1;
  const isFirstPost = postIndex === 0;

  const nextPost = blogData[postIndex + 1];
  const prevPost = blogData[postIndex - 1];

  return (
    <>
      <div className="next-prev-post">
        <div className="row g-3">
          {!isFirstPost && (
            <div className="col-md-6 text-md-start text-center">
              <div className="post-arrow">
                <Link
                  to={`${process.env.PUBLIC_URL}/blog/${prevPost.id}`}
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: 'smooth' })
                  }
                >
                  <h6>Previous Post</h6>
                </Link>
                <Link
                  to={`${process.env.PUBLIC_URL}/blog/${prevPost.id}`}
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: 'smooth' })
                  }
                >
                  <h5>{prevPost.title}</h5>
                </Link>
              </div>
            </div>
          )}
          {!isLastPost && (
            <div className="col-md-6 text-md-end text-center">
              <div className="post-arrow">
                <Link
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: 'smooth' })
                  }
                  to={`${process.env.PUBLIC_URL}/blog/${nextPost.id}`}
                >
                  <h6>Next Post</h6>
                </Link>
                <Link
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: 'smooth' })
                  }
                  to={`${process.env.PUBLIC_URL}/blog/${nextPost.id}`}
                >
                  <h5>{nextPost.title}</h5>
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default NextAndPrevPost;
