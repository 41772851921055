import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../../common/SectionTitle';
import { faq } from '../../../constants';

function FaqWrap() {
  return (
    <>
      <div className="feedback-section pt-120">
        <div className="container">
          <SectionTitle title="Questions" />
          <div className="row d-flex justify-content-center">
            <div className="col-lg-4 col-md-8 col-sm-8">
              <div className="section-card faq-section-card">
                <h2 className="mb-4 pb-1">Popular Questions</h2>
                <Link
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: 'smooth' })
                  }
                  to={`${process.env.PUBLIC_URL}/contact-us`}
                  className="eg-btn hover-btn sectoin-card-btn"
                >
                  <span>
                    Contact Us
                    <img
                      src={
                        process.env.PUBLIC_URL + '/images/icon/arrow-servc.svg'
                      }
                      alt="images"
                    />
                  </span>
                </Link>
              </div>
            </div>
            <div className="col-lg-8 text-center">
              {/* faq accordion */}
              <div className="faq-wrap pb-120">
                {faq.map((item, index) => (
                  <div key={index} className="faq-item hover-btn">
                    <span />
                    <h5
                      id={`heading${index}`}
                      className="accordion-button collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapse${index}`}
                      aria-controls={`collapse${index}`}
                    >
                      {`${String(index + 1).padStart(2, '0')}. ${
                        item.question
                      }`}
                    </h5>
                    <div
                      id={`collapse${index}`}
                      className="accordion-collapse collapse"
                      aria-labelledby={`heading${index}`}
                    >
                      <div className="faq-body">{item.answer}</div>
                    </div>
                  </div>
                ))}
              </div>
              {/* faq-form */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FaqWrap;
