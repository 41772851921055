import React from 'react';
import { INITIAL_WOW_DELAY } from '../../../constants/variables';
import { dataWowDelayHandler } from '../../../utils/delayHandler';
import Sponsor from '../../common/Sponsor';

function ServiceProgressArea() {
  return (
    <>
      <div
        className="section-title pt-0 wow animate fadeInDown mt-70"
        data-wow-duration={INITIAL_WOW_DELAY}
        data-wow-delay={dataWowDelayHandler(0.1)}
      >
        <h2>Technologies</h2>
      </div>
      <div className="service-details-area mt-20">
        <div className="work-process">
          <div className="row pt-60 d-flex justify-content-center">
            <Sponsor />
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiceProgressArea;
