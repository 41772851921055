import React from 'react';

function EmailSentComponent() {
  return (
    <div className="pt-120 pb-120" style={{ width: 'auto' }}>
      <div className="container">
        <h3>Your message was sent successfully. Thank you!</h3>
      </div>
    </div>
  );
}

export default EmailSentComponent;
