import React from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';

SwiperCore.use([Autoplay]);
function Sponsor() {
  const sponsorSlide = {
    slidesPerView: 7,
    spaceBetween: 10,
    loop: false,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    autoplay: {
      delay: 5000,
    },
    // Responsive breakpoints
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      // when window width is >= 480px
      480: {
        slidesPerView: 2,
        spaceBetween: 30,
      },

      // when window width is >= 640px
      768: {
        slidesPerView: 3,
        spaceBetween: 40,
      },
      // when window width is >= 992px
      992: {
        slidesPerView: 6,
        spaceBetween: 40,
      },
      // when window width is >= 1400px
      1400: {
        slidesPerView: 6,
        spaceBetween: 40,
      },
    },
  };
  return (
    <>
      <div className="mt-20 sponsor-section-title">
        <h2>Programming Languages</h2>
      </div>
      <div className="sponsor-section">
        <div className="container">
          <div className="row">
            <Swiper {...sponsorSlide} className="swiper sponsor-slider-dark">
              <div className="swiper-wrapper">
                <SwiperSlide className="swiper-slide">
                  <Link to={'#'} className="sponsor-item">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/images/bg/sponsor/programming-languages/javascript.png'
                      }
                      className="circle-image"
                      title={'JavaScript'}
                      alt="images"
                    />
                  </Link>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <a
                    href="https://www.typescriptlang.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="sponsor-item"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/images/bg/sponsor/programming-languages/typescript.png'
                      }
                      className="circle-image"
                      title={'TypeScript'}
                      alt="images"
                    />
                  </a>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <a
                    href="https://www.php.net/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="sponsor-item"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/images/bg/sponsor/programming-languages/php.png'
                      }
                      className="circle-image medium"
                      alt="images"
                      title={'PHP'}
                    />
                  </a>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <a
                    href="https://go.dev/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="sponsor-item"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/images/bg/sponsor/programming-languages/golang.png'
                      }
                      className="circle-image medium"
                      alt="images"
                      title={'Go'}
                    />
                  </a>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <a
                    href="https://www.python.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="sponsor-item"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/images/bg/sponsor/programming-languages/python.png'
                      }
                      className="circle-image"
                      alt="images"
                      title={'Python'}
                    />
                  </a>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <Link to={'#'} className="sponsor-item">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/images/bg/sponsor/programming-languages/html.png'
                      }
                      className="circle-image"
                      title={'HTML'}
                      alt="images"
                    />
                  </Link>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <Link to={'#'} className="sponsor-item">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/images/bg/sponsor/programming-languages/css.png'
                      }
                      className="circle-image"
                      alt="images"
                      title={'CSS'}
                    />
                  </Link>
                </SwiperSlide>
              </div>
            </Swiper>
          </div>
        </div>
      </div>

      <div className="mt-20 sponsor-section-title">
        <h2>Frontend Frameworks</h2>
      </div>
      <div className="sponsor-section">
        <div className="container">
          <div className="row">
            <Swiper {...sponsorSlide} className="swiper sponsor-slider-dark">
              <div className="swiper-wrapper">
                <SwiperSlide className="swiper-slide">
                  <a
                    href="https://react.dev/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="sponsor-item"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/images/bg/sponsor/frontend/reactjs.png'
                      }
                      className="circle-image"
                      title={'React'}
                    />
                  </a>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <a
                    href="https://nextjs.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="sponsor-item"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/images/bg/sponsor/frontend/nextjs.png'
                      }
                      className="circle-image contrast"
                      alt="images"
                      title={'Next.js'}
                    />
                  </a>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <a
                    href="https://graphql.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="sponsor-item"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/images/bg/sponsor/frontend/graphql.png'
                      }
                      className="circle-image"
                      alt="images"
                      title={'GraphQL'}
                    />
                  </a>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <Link to={'#'} className="sponsor-item">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/images/bg/sponsor/frontend/web3.png'
                      }
                      className="circle-image big contrast"
                      alt="images"
                      title={'Web3'}
                    />
                  </Link>
                </SwiperSlide>
              </div>
            </Swiper>
          </div>
        </div>
      </div>

      <div className="mt-20 sponsor-section-title">
        <h2>Backend Frameworks</h2>
      </div>
      <div className="sponsor-section">
        <div className="container">
          <div className="row">
            <Swiper {...sponsorSlide} className="swiper sponsor-slider-dark">
              <div className="swiper-wrapper">
                <SwiperSlide className="swiper-slide">
                  <a
                    href="https://nodejs.org/en"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="sponsor-item"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/images/bg/sponsor/backend/nodejs.png'
                      }
                      className="circle-image medium"
                      alt="images"
                      title={'Node.js'}
                    />
                  </a>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <a
                    href="https://nestjs.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="sponsor-item"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/images/bg/sponsor/backend/nestjs.svg'
                      }
                      className="circle-image"
                      alt="images"
                      title={'Nest.js'}
                    />
                  </a>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <a
                    href="https://expressjs.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="sponsor-item"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/images/bg/sponsor/backend/expressjs.png'
                      }
                      className="circle-image big contrast"
                      alt="images"
                      title={'Express.js'}
                    />
                  </a>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <a
                    href="https://symfony.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="sponsor-item"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/images/bg/sponsor/backend/symfony.png'
                      }
                      className="circle-image big contrast"
                      alt="images"
                      title={'Symfony'}
                    />
                  </a>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <a
                    href="https://flask.palletsprojects.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="sponsor-item"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/images/bg/sponsor/backend/flask.svg'
                      }
                      className="circle-image big contrast"
                      title={'Flask'}
                      alt="images"
                    />
                  </a>
                </SwiperSlide>
              </div>
            </Swiper>
          </div>
        </div>
      </div>

      <div className="mt-20 sponsor-section-title">
        <h2>DevOps and Cloud Services</h2>
      </div>
      <div className="sponsor-section">
        <div className="container">
          <div className="row">
            <Swiper {...sponsorSlide} className="swiper sponsor-slider-dark">
              <div className="swiper-wrapper">
                <SwiperSlide className="swiper-slide">
                  <a
                    href="https://www.docker.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="sponsor-item"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/images/bg/sponsor/devops/docker.png'
                      }
                      className="circle-image big"
                      alt="images"
                      title={'Docker'}
                    />
                  </a>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <a
                    href="https://aws.amazon.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="sponsor-item"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/images/bg/sponsor/devops/aws.svg'
                      }
                      className="circle-image"
                      alt="images"
                      title={'AWS'}
                    />
                  </a>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <a
                    href="https://cloud.google.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="sponsor-item"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/images/bg/sponsor/devops/gcloud.png'
                      }
                      className="circle-image extra-big"
                      alt="images"
                      title={'Google Cloud'}
                    />
                  </a>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <a
                    href="https://azure.microsoft.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="sponsor-item"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/images/bg/sponsor/devops/azure.svg'
                      }
                      className="circle-image"
                      alt="images"
                      title={'Azure'}
                    />
                  </a>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <a
                    href="https://kubernetes.io/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="sponsor-item"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/images/bg/sponsor/devops/kubernetes.png'
                      }
                      className="circle-image"
                      alt="images"
                      title={'Kubernetes'}
                    />
                  </a>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <a
                    href="https://www.terraform.io/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="sponsor-item"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/images/bg/sponsor/devops/terraform.png'
                      }
                      className="circle-image extra-big"
                      alt="images"
                      title={'Terraform'}
                    />
                  </a>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <a
                    href="https://www.ansible.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="sponsor-item"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/images/bg/sponsor/devops/ansible.png'
                      }
                      className="circle-image extra-big contrast"
                      alt="images"
                      title={'Ansible'}
                    />
                  </a>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <a
                    href="https://www.packer.io/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="sponsor-item"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/images/bg/sponsor/devops/packer.png'
                      }
                      className="circle-image extra-big"
                      alt="images"
                      title={'Packer'}
                    />
                  </a>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <a
                    href="https://www.vagrantup.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="sponsor-item"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/images/bg/sponsor/devops/vagrant.png'
                      }
                      className="circle-image extra-big"
                      alt="images"
                      title={'Vagrant'}
                    />
                  </a>
                </SwiperSlide>
              </div>
            </Swiper>
          </div>
        </div>
      </div>

      <div className="mt-20 sponsor-section-title">
        <h2>Database Management</h2>
      </div>
      <div className="sponsor-section">
        <div className="container">
          <div className="row">
            <Swiper {...sponsorSlide} className="swiper sponsor-slider-dark">
              <div className="swiper-wrapper">
                <SwiperSlide className="swiper-slide">
                  <a
                    href="https://www.mysql.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="sponsor-item"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/images/bg/sponsor/database/mysql.png'
                      }
                      className="circle-image"
                      alt="MySQL"
                      title="MySQL"
                    />
                  </a>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <a
                    href="https://www.postgresql.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="sponsor-item"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/images/bg/sponsor/database/postgresql.png'
                      }
                      className="circle-image extra-big"
                      alt="PostgreSQL"
                      title="PostgreSQL"
                    />
                  </a>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <a
                    href="https://www.mongodb.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="sponsor-item"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/images/bg/sponsor/database/mongodb.png'
                      }
                      className="circle-image extra-big"
                      alt="MongoDB"
                      title="MongoDB"
                    />
                  </a>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <a
                    href="https://firebase.google.com/docs/database"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="sponsor-item"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/images/bg/sponsor/database/firebase.png'
                      }
                      className="circle-image big"
                      alt="Firebase"
                      title="Firebase"
                    />
                  </a>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <a
                    href="https://aerospike.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="sponsor-item"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/images/bg/sponsor/database/aerospike.png'
                      }
                      className="circle-image extra-big"
                      alt="Aerospike"
                      title="Aerospike"
                    />
                  </a>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <a
                    href="https://aws.amazon.com/neptune/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="sponsor-item"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/images/bg/sponsor/database/neptune.png'
                      }
                      className="circle-image"
                      alt="Amazon Neptune"
                      title="Amazon Neptune"
                    />
                  </a>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <a
                    href="https://redis.io/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="sponsor-item"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/images/bg/sponsor/database/redis.png'
                      }
                      className="circle-image big"
                      alt="Redis"
                      title="Redis"
                    />
                  </a>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <a
                    href="https://mariadb.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="sponsor-item"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/images/bg/sponsor/database/mariadb.png'
                      }
                      className="circle-image"
                      alt="MariaDB"
                      title="MariaDB"
                    />
                  </a>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <a
                    href="https://www.oracle.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="sponsor-item"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/images/bg/sponsor/database/oracle.png'
                      }
                      className="circle-image"
                      alt="Oracle"
                      title="Oracle"
                    />
                  </a>
                </SwiperSlide>
              </div>
            </Swiper>
          </div>
        </div>
      </div>

      <div className="mt-20 sponsor-section-title">
        <h2>Version Control</h2>
      </div>
      <div className="sponsor-section">
        <div className="container">
          <div className="row">
            <Swiper {...sponsorSlide} className="swiper sponsor-slider-dark">
              <div className="swiper-wrapper">
                <SwiperSlide className="swiper-slide">
                  <a
                    href="https://git-scm.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="sponsor-item"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/images/bg/sponsor/version-control/git.png'
                      }
                      className="circle-image medium"
                      alt="Git"
                      title="Git"
                    />
                  </a>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <a
                    href="https://github.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="sponsor-item"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/images/bg/sponsor/version-control/github.png'
                      }
                      className="circle-image big"
                      alt="GitHub"
                      title="GitHub"
                    />
                  </a>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <a
                    href="https://about.gitlab.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="sponsor-item"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/images/bg/sponsor/version-control/gitlab.png'
                      }
                      className="circle-image extra-big"
                      alt="GitLab"
                      title="GitLab"
                    />
                  </a>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <a
                    href="https://bitbucket.org/product"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="sponsor-item"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/images/bg/sponsor/version-control/bitbucket.png'
                      }
                      className="circle-image extra-big"
                      alt="Bitbucket"
                      title="Bitbucket"
                    />
                  </a>
                </SwiperSlide>
              </div>
            </Swiper>
          </div>
        </div>
      </div>

      <div className="mt-20 sponsor-section-title">
        <h2>UI Libraries and Styling</h2>
      </div>
      <div className="sponsor-section">
        <div className="container">
          <div className="row">
            <Swiper {...sponsorSlide} className="swiper sponsor-slider-dark">
              <div className="swiper-wrapper">
                <SwiperSlide className="swiper-slide">
                  <a
                    href="https://mui.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="sponsor-item"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/images/bg/sponsor/ui/materialui.svg'
                      }
                      className="circle-image"
                      alt="Material-UI"
                      title="Material-UI"
                    />
                  </a>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <a
                    href="https://mantine.dev/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="sponsor-item"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/images/bg/sponsor/ui/mantine.svg'
                      }
                      className="circle-image"
                      alt="Mantine"
                      title="Mantine"
                    />
                  </a>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <a
                    href="https://getbootstrap.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="sponsor-item"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/images/bg/sponsor/ui/bootstrap.png'
                      }
                      className="circle-image extra-big"
                      alt="Bootstrap"
                      title="Bootstrap"
                    />
                  </a>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <a
                    href="https://tailwindcss.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="sponsor-item"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/images/bg/sponsor/ui/tailwind.svg'
                      }
                      className="circle-image"
                      alt="Tailwind CSS"
                      title="Tailwind CSS"
                    />
                  </a>
                </SwiperSlide>
              </div>
            </Swiper>
          </div>
        </div>
      </div>

      <div className="mt-40 sponsor-section-title align-content-center">
        <h2>Testing</h2>
      </div>
      <div className="sponsor-section mb-5">
        <div className="container">
          <div className="row">
            <Swiper {...sponsorSlide} className="swiper sponsor-slider-dark">
              <div className="swiper-wrapper">
                <SwiperSlide className="swiper-slide">
                  <a
                    href="https://jestjs.io/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="sponsor-item"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/images/bg/sponsor/testing/jest.png'
                      }
                      className="circle-image medium"
                      alt="Jest"
                      title="Jest"
                    />
                  </a>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <a
                    href="https://www.selenium.dev/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="sponsor-item"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/images/bg/sponsor/testing/selenium.svg'
                      }
                      className="circle-image"
                      alt="Selenium"
                      title="Selenium"
                    />
                  </a>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <a
                    href="https://phpunit.de/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="sponsor-item"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/images/bg/sponsor/testing/phpunit.png'
                      }
                      className="circle-image big contrast"
                      alt="PHPUnit"
                      title="PHPUnit"
                    />
                  </a>
                </SwiperSlide>
              </div>
            </Swiper>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sponsor;
