import React from 'react';
import { teamPrinciples } from '../../../constants';
import AboutDetailsLeft from '../../common/AboutDetailsLeft';

function TeamPrinciplesDetailed() {
  const workProItems = [
    { id: 1, icon: 'workp1.svg', title: 'Research' },
    { id: 2, icon: 'workp2.svg', title: 'Idea' },
    { id: 3, icon: 'workp3.svg', title: 'Develop' },
    { id: 4, icon: 'workp4.svg', title: 'Launch' },
  ];

  return (
    <>
      <div className="service-section2 pt-120">
        <div className="container">
          <div className="row d-flex justify-content-lg-start justify-content-center gy-5">
            <AboutDetailsLeft />
            <div className="col-lg-7 col-md-7">
              <div className="section-title text-center">
                <h2>Our Values</h2>
              </div>
              <div className="service-details-area">
                <div className="work-process">
                  <div className="row g-3">
                    {workProItems.map((item) => (
                      <div
                        key={item.id}
                        className="col-lg-3 col-md-6 col-sm-3 col-6"
                      >
                        <div className="work-pro-item">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              `/images/icon/${item.icon}`
                            }
                            alt="images"
                          />
                          <h4>{item.title}</h4>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="service-details-content">
                  {teamPrinciples.slice(4).map((teamPrinciple, index) => {
                    return (
                      <div key={index}>
                        <h4>{teamPrinciple.title}</h4>
                        <p className="para">{teamPrinciple.description}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TeamPrinciplesDetailed;
