import React from 'react';
import { Link } from 'react-router-dom';
import { dataWowDelayHandler } from '../../../utils/delayHandler';
import { INITIAL_WOW_DELAY } from '../../../constants/variables';

const Cards = ({ data, link }) => {
  const splitTitleInTwoRows = (title) => {
    const titleArray = title.split(' ');
    const middlewareArray = titleArray.slice(0, titleArray.length / 2);
    return (
      middlewareArray.join(' ') +
      '<br />' +
      titleArray.slice(titleArray.length / 2).join(' ')
    );
  };

  return (
    <>
      <div className="row d-flex justify-content-center g-4">
        {data?.slice(0, 6).map((teamPrinciple, index) => (
          <div
            key={index}
            className={`col-xl-4 col-lg-4 col-md-6 col-sm-8 order-1 wow animate fadeInDown ${
              index === 2 ? 'custom-service-item' : ''
            }`}
            data-wow-duration={INITIAL_WOW_DELAY}
            data-wow-delay={dataWowDelayHandler(0.1 * (index + 1))}
          >
            {index === 2 ? (
              <div className="section-card all-service-card2">
                <h2>Our Values</h2>
                <Link
                  to={`${process.env.PUBLIC_URL}/${link}`}
                  className="eg-btn hover-btn sectoin-card-btn"
                >
                  <span>
                    See more{' '}
                    <img
                      src={
                        process.env.PUBLIC_URL + '/images/icon/arrow-servc.svg'
                      }
                      alt="images"
                    />
                  </span>
                </Link>
              </div>
            ) : (
              <div className="service-item hover-btn service-mt-30 d-flex flex-row align-items-center justify-content-between">
                <div className="s-content">
                  <div className="number">{`0${index + 1}`}</div>
                  <h4>
                    <Link
                      to={`${process.env.PUBLIC_URL}/${link}`}
                      dangerouslySetInnerHTML={{
                        __html: splitTitleInTwoRows(teamPrinciple.title),
                      }}
                    />
                  </h4>
                </div>
                <span />
                <div className="s-icon">
                  {React.cloneElement(teamPrinciple.svg, {
                    style: { fill: '#e6e6e6', width: '100%', height: 'auto' },
                  })}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default Cards;
