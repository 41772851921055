import React from 'react';
import TitleHolder from '../../common/TitleHolder';
import { OUR_SERVICES } from '../../../constants/variables';

function HomeoneService() {
  return (
    <>
      <div className="service-section pt-120 pb-120">
        <div className="container position-relative">
          <img
            src={process.env.PUBLIC_URL + '/images/icon/dotted.svg'}
            className="dotted-circle"
            alt="images"
          />
          <img
            src={process.env.PUBLIC_URL + '/images/icon/dot-frame.svg'}
            className="dotted-frame"
            alt="images"
          />
          <TitleHolder title={OUR_SERVICES} titleSide="start" />
          <div className="container">
            <div className="row">
              <div className="container">
                <div className="row mb-5">
                  <div className="col-lg-6 col-md-12">
                    <h4>Information and Research</h4>
                    <p className="para">
                      Unlock the power of informed decision-making with our
                      Information and Research services. We delve deep into
                      data, conduct thorough market research, and provide
                      valuable insights to guide your business strategies.
                      Whether you're exploring new markets, assessing
                      competitors, or seeking to understand industry trends, our
                      comprehensive research solutions empower you with the
                      knowledge needed to stay ahead.
                    </p>
                  </div>
                  <div className="col-lg-3 col-md-12">
                    <img
                      className="picture-container"
                      style={{ width: '350px', height: '300px' }}
                      src={process.env.PUBLIC_URL + '/images/1.jpg'}
                      alt="images"
                    />
                  </div>
                </div>
                <div className="row  mb-5">
                  <div className="col-lg-6 col-md-12">
                    <img
                      style={{ width: '300px', height: '300px' }}
                      src={process.env.PUBLIC_URL + '/images/2.jpg'}
                      alt="images"
                    />
                  </div>
                  <div className="col-lg-6 col-md-12 ">
                    <h4>UI & Front-end Development</h4>
                    <p className="para">
                      Bring your vision to life with our UI & Front-end
                      Development expertise. We specialize in turning design
                      concepts into pixel-perfect, responsive interfaces. Our
                      team combines aesthetic flair with cutting-edge
                      technologies to deliver user interfaces that not only look
                      stunning but also provide a smooth and intuitive user
                      experience. Elevate your digital presence with our UI and
                      front-end development solutions.
                    </p>
                  </div>
                </div>
                <div className="row  mb-5">
                  <div className="col-lg-6 col-md-12">
                    <h4>Core Development</h4>
                    <p className={'para'}>
                      Building the backbone of your digital initiatives is our
                      forte. With Core Development services, we architect robust
                      and scalable solutions tailored to meet your unique
                      requirements. From database design to backend development,
                      our team is committed to developing the foundational
                      elements that power your applications. Trust us to turn
                      your ideas into functional, high-performance software
                      solutions.
                    </p>
                  </div>
                  <div className="col-lg-3 col-md-12">
                    <div style={{ fontSize: '16px', color: 'white' }}>
                      <img
                        style={{ width: '300px', height: '300px' }}
                        src={process.env.PUBLIC_URL + '/images/3.jpg'}
                        alt="images"
                      />
                    </div>
                  </div>
                </div>
                <div className="row row">
                  <div className="col-lg-6 col-md-12">
                    <div style={{ fontSize: '16px', color: 'white' }}>
                      <img
                        style={{ width: '300px', height: '300px' }}
                        src={process.env.PUBLIC_URL + '/images/4.jpg'}
                        alt="images"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <h4>Application Lifecycle and Support</h4>
                    <p className={'para'}>
                      Seamlessly manage your application's lifecycle with our
                      comprehensive support services. From inception through
                      deployment and ongoing maintenance, we offer end-to-end
                      support to ensure the longevity and success of your
                      applications. Our dedicated team is equipped to handle the
                      challenges of application management, providing reliable
                      solutions to keep your software running smoothly. Whether
                      you need assistance in optimizing performance,
                      implementing updates, or resolving issues, we are
                      committed to supporting your digital initiatives at every
                      stage.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomeoneService;
