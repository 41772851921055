import React from 'react';
import ContactWrap from './ContactWrap';

function ContactUsPage() {
  return (
    <>
      <ContactWrap />
    </>
  );
}

export default ContactUsPage;
