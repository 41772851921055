import React from 'react';
import TitleHolder from '../../common/TitleHolder';
import Cards from '../service/Cards';
import { teamPrinciples } from '../../../constants';
import { ABOUT_US_PATH } from '../../../constants/variables';

function TeamPrinciples() {
  return (
    <>
      <div className="service-section pt-120">
        <div className="container position-relative">
          <img
            src={process.env.PUBLIC_URL + '/images/icon/dotted.svg'}
            className="dotted-circle"
            alt="images"
          />
          <img
            src={process.env.PUBLIC_URL + '/images/icon/dot-frame.svg'}
            className="dotted-frame"
            alt="images"
          />
          <TitleHolder title="Team Principles" titleSide="end" />
          <Cards data={teamPrinciples} link={ABOUT_US_PATH} />
        </div>
      </div>
    </>
  );
}

export default TeamPrinciples;
