import {
  AndroidApp,
  CalendarGraph,
  Hashtag,
  LightBulb,
  Zar,
} from '../assets/images/icon';

import baidoc from '../assets/images/team/baidoc.jpeg';
import oprea from '../assets/images/team/oprea.jpeg';
import donca from '../assets/images/team/donca.jpg';
import huzau from '../assets/images/team/huzau.jpg';
import petrean from '../assets/images/team/petrean.jpg';
import seres from '../assets/images/team/seres.jpeg';

const team = [
  {
    image: huzau,
    imageType: 'jpg',
    name: 'Huzau Damian',
    title: 'Frontend Developer / QA',
    linkedin: 'huzau-damian-247aa8130/',
  },
  {
    image: donca,
    imageType: 'jpg',
    name: 'Donca Ionut-Catalin',
    title: 'DevOps Engineer',
    linkedin: 'ionut-catalin-donca-59548612a/',
  },
  {
    image: petrean,
    imageType: 'png',
    name: 'Petrean Tudor',
    title: 'Team Lead',
    linkedin: 'tudor-petrean-377b17a6/',
  },
  {
    image: baidoc,
    imageType: 'jpeg',
    name: 'Baidoc Vlad',
    title: 'Frontend Developer',
    linkedin: 'vlad-baidoc-a455a5122/',
  },
  {
    image: oprea,
    imageType: 'jpg',
    name: 'Oprea Rares',
    title: 'Software Developer',
    linkedin: 'raresoprea/',
  },
  {
    image: seres,
    imageType: 'jpg',
    name: 'Seres Daniel',
    title: 'Software Developer',
    linkedin: 'daniel-seres-69767219b/',
  },
];
const faq = [
  {
    question: 'How does your team collaborate on projects?',
    answer:
      'We foster a collaborative work environment, combining our diverse skills to address project challenges. Regular meetings, communication tools, and agile methodologies help ensure efficient teamwork.',
  },
  {
    question: 'How do you ensure the security of the projects you work on?',
    answer:
      "Security is a top priority for us. We follow industry best practices, conduct regular security audits, and stay updated on the latest security trends to protect our projects and clients' data.",
  },
  {
    question: 'Can you handle projects of varying sizes and complexity?',
    answer:
      "Absolutely. Our team is equipped to handle a wide range of projects, from small web applications to large-scale software development. We tailor our approach to match the project's complexity.",
  },
  {
    question:
      'How do you handle communication with clients throughout a project?',
    answer:
      "We maintain transparent and open communication with our clients. You'll have access to project updates, and we're always ready to address questions or concerns promptly.",
  },
  {
    question: 'Do you offer post-launch support for projects?',
    answer:
      'Yes, we provide post-launch support to ensure that your project continues to perform optimally. This includes updates, maintenance, and addressing any issues that may arise.',
  },
  {
    question:
      'What qualities make your team stand out in the development industry?',
    answer:
      'Our team excels in creativity, problem-solving, and a commitment to excellence. We pride ourselves on delivering high-quality solutions that meet and exceed client expectations.',
  },
];
const contactInfo = {
  zipCode: '400000',
  street: 'Cluj',
  city: 'Cluj-Napoca',
  country: 'Romania',
  phone: '+40 757019599',
  email: 'hello@devemi.io',
};
const developmentTeamQuotes = [
  'Crafting digital dreams one line of code at a time.',
  "In the world of code, we're the architects of innovation.",
  'Turning ideas into reality through the power of code.',
  'Coding together, creating together, achieving together.',
  'In the realm of pixels, we are the artists of innovation.',
  'Developing solutions, one byte at a time.',
  "Our code is the bridge to tomorrow's technology.",
  'Turning keystrokes into masterpieces.',
  'Unleashing the potential of ones and zeros.',
  "Coding is our superpower, and we're here to save the day.",
];

const teamPrinciples = [
  {
    title: 'Committed to Excellence in Code',
    description:
      'Our success is symbiotic with that of our clients. We are steadfast in our commitment to delivering top-notch code and software solutions.',
    svg: <LightBulb />,
  },
  {
    title: 'Dedicated to Client Satisfaction',
    description:
      'We believe in building long-term relationships with our clients. We are committed to providing exceptional service and ensuring client satisfaction.',
    svg: <Hashtag />,
  },
  {
    title: 'Innovative Solutions, Impactful Products',
    description:
      'We specialize in engineering impactful digital products rooted in innovative solutions. Our code is the backbone of digital experiences that transcend expectations.',
    svg: <CalendarGraph />,
  },
  {
    title: 'Collaborative,Creative, Curious',
    description:
      'We are a team of creative problem solvers, dedicated to crafting exceptional software solutions. We are committed to delivering outstanding code quality, innovative solutions, and fostering a culture of excellence.',
    svg: <AndroidApp />,
  },
  {
    title: 'Empowering Developer Potential',
    description:
      "We believe in empowering developers to reach their full potential. Our work extends beyond projects; it's a commitment to nurturing talent, continuous learning, and fostering individual growth within our team.",
    svg: <Zar />,
  },
  {
    title: 'Cultivating Curiosity and Code Innovation',
    description:
      'We cultivate an environment of curiosity, constant innovation, and an unquenchable thirst for knowledge in coding. This culture propels us forward, ensuring we stay at the forefront of technological advancements.',
    svg: <LightBulb />,
  },
  {
    title: 'Guiding Digital Excellence',
    description:
      'We guide our clients toward digital excellence. From code architecture to implementation, we stand as technical partners, ensuring a seamless integration of cutting-edge solutions.',
    svg: <LightBulb />,
  },
  {
    title: 'Efficient Collaboration',
    description:
      'We encourage meaningful and efficient collaborations, both within our development team and with our clients. Strong teamwork is at the core of our ability to deliver efficient and scalable software solutions.',
    svg: <LightBulb />,
  },
  {
    title: 'Supporting Developers',
    description:
      "We stand by developers determined to break the mold. Our support goes beyond traditional roles; we champion those daring enough to push the boundaries of what's possible in software development.",
    svg: <LightBulb />,
  },
];

export { team, faq, contactInfo, developmentTeamQuotes, teamPrinciples };
