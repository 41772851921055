import React from 'react';
import { Link } from 'react-router-dom';
import { INITIAL_WOW_DELAY } from '../../../constants/variables';
import { dataWowDelayHandler } from '../../../utils/delayHandler';
import TitleHolder from '../../common/TitleHolder';

function ServiceDetailsWrap() {
  return (
    <>
      <div className="service-section2 pt-80">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-6 col-md-8 col-sm-8 pt-50">
              <TitleHolder title="Services" titleSide={'right'} />

              <div
                className="about-card wow animate fadeInDown"
                data-wow-duration={INITIAL_WOW_DELAY}
                data-wow-delay={dataWowDelayHandler(0.1)}
              >
                <div className="about-content">
                  <h5>Professional Skills</h5>
                  <h3>We are a dynamic, full-stack web development agency.</h3>
                  <p>
                    In today's digital world, success comes from integration,
                    innovation, and adaptability. Our team specializes in
                    crafting web applications that are not only visually
                    appealing but also highly functional and scalable. From the
                    front-end design that captivates users to the back-end
                    systems that ensure efficiency and security, we cover every
                    facet of web development. Our DevOps experts streamline the
                    development lifecycle, ensuring rapid deployment and
                    continuous integration. Our commitment is to turn your
                    ambitious digital concepts into tangible realities, ready to
                    meet today's needs and tomorrow's challenges.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 d-lg-block d-none">
              <div
                className="about-full-img wow animate fadeInDown main-menu"
                data-wow-duration={INITIAL_WOW_DELAY}
                data-wow-delay={dataWowDelayHandler(0.1)}
              >
                <img
                  src={process.env.PUBLIC_URL + '/images/bg/service-skill.png'}
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="section-title pt-80">
            <h2>Services Details</h2>
          </div>
          <div className="row d-flex justify-content-lg-start justify-content-center gy-5">
            <div className="col-lg-12 col-md-12">
              <div className="service-details-area">
                <div className="work-process">
                  <h3>Work Process</h3>
                  <div className="row g-3">
                    <div className="col-lg-3 col-md-6 col-sm-3 col-6">
                      <div className="work-pro-item">
                        <img
                          src={
                            process.env.PUBLIC_URL + '/images/icon/workp1.svg'
                          }
                          alt="images"
                        />
                        <h4>
                          <Link to={'#'}>Research</Link>
                        </h4>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-3 col-6">
                      <div className="work-pro-item">
                        <img
                          src={
                            process.env.PUBLIC_URL + '/images/icon/workp2.svg'
                          }
                          alt="images"
                        />
                        <h4>
                          <Link to={'#'}>Idea</Link>
                        </h4>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-3 col-6">
                      <div className="work-pro-item">
                        <img
                          src={
                            process.env.PUBLIC_URL + '/images/icon/workp3.svg'
                          }
                          alt="images"
                        />
                        <h4>
                          <Link to={'#'}>Develop</Link>
                        </h4>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-3 col-6">
                      <div className="work-pro-item">
                        <img
                          src={
                            process.env.PUBLIC_URL + '/images/icon/workp4.svg'
                          }
                          alt="images"
                        />
                        <h4>
                          <Link to={'#'}>Launch</Link>
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="service-details-content mt-30">
                    <h3>Web Development Expertise</h3>
                    <p className="para">
                      At DevEmi, our team of six brings together a diverse set
                      of skills and experiences, allowing us to tackle a wide
                      range of projects. This dynamic blend enables us to tackle
                      a broad spectrum of projects with agility and precision.
                      Our expertise isn't just confined to coding, it
                      encompasses the entire spectrum of web application
                      development. From conceptualizing user-centric designs in
                      the frontend to engineering robust backend architectures,
                      we craft every layer of the web experience.
                    </p>
                    <h4>Full Stack Development</h4>
                    <p>
                      Empowered with a robust foundation in various programming
                      languages, our team seamlessly bridges the gap between
                      frontend and backend development. On the frontend, we
                      specialize in crafting dynamic user interfaces with
                      React.js, React Native, Next.js, and leverage GraphQL and
                      Web3.js for efficient data retrieval and manipulation. Our
                      backend expertise includes Node.js, NestJS, Symfony (PHP),
                      Flask (Python), and Microservices architecture, ensuring
                      scalable and performant server-side solutions. With
                      proficiency in both traditional and modern stacks, our
                      full-stack capabilities enable us to deliver comprehensive
                      and cutting-edge solutions tailored to your project's
                      unique requirements.
                    </p>
                    <h4>Database Management</h4>
                    <p>
                      Data is the heartbeat of modern applications. Our
                      proficiency spans across relational databases like MySQL
                      and PostgreSQL to NoSQL solutions such as MongoDB and
                      Firebase. We specialize in optimizing data storage and
                      retrieval, ensuring your applications run efficiently.
                    </p>
                    <h4>Blockchain Development</h4>
                    <p>
                      Explore the future with our expertise in Web3.js,
                      empowering you to integrate blockchain technology
                      seamlessly into your projects.
                    </p>
                    <h4>Version Control and Collaboration</h4>
                    <p>
                      Efficient teamwork relies on streamlined version control.
                      We leverage Git, GitHub, GitLab, and Bitbucket to manage
                      code collaboratively, ensuring smooth development
                      workflows.
                    </p>
                    <h4>DevOps and Cloud Services</h4>
                    <p>
                      Navigating the cloud landscape is second nature to us.
                      From containerization with Docker to orchestrating
                      deployments on AWS, Google Cloud Platform, and Azure, we
                      provide scalable and reliable solutions.
                    </p>
                    <h4>CI/CD Processes</h4>
                    <p>
                      Automate your development pipeline with confidence using
                      industry-standard CI/CD processes. Our team is well-versed
                      in Jenkins, GitHub Actions, GitLab CI, and Bamboo.
                    </p>
                    <h4>Infrastructure as Code</h4>
                    <p>
                      Transform infrastructure management with tools like
                      Terraform, Ansible, Packer, and Vagrant, enabling seamless
                      scalability and resource provisioning.
                    </p>
                    <h4>Monitoring and Logging</h4>
                    <p>
                      Ensure the health and performance of your applications
                      with robust monitoring solutions like the ELK stack,
                      Grafana, Prometheus, Datadog, and Zabbix.
                    </p>
                    <h4>Testing and Quality Assurance</h4>
                    <p>
                      Quality is at the core of our development process. From
                      unit testing with Jest to end-to-end testing with Selenium
                      and TestComplete, we ensure your applications are robust
                      and reliable.
                    </p>
                    <h4>UI Libraries and Styling</h4>
                    <p>
                      Craft visually stunning and user-friendly interfaces using
                      popular UI libraries like Material UI and Mantine,
                      combined with styling techniques such as Tailwind CSS, CSS
                      modules, and SCSS.
                    </p>
                    <h4>Project Management and Methodologies</h4>
                    <p>
                      Agile, Waterfall, Scrum – we adapt to your project's
                      needs, ensuring effective project management with tools
                      like Jira or Youtrack.
                    </p>
                    <h4>Design and Prototyping</h4>
                    <p>
                      Bring your ideas to life with our design and prototyping
                      expertise using Figma.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiceDetailsWrap;
