import React from 'react';
// import Breadcrumb from '../../common/Breadcrumb';
import BlogDetailsWrap from './BlogDetailsWrap';
import { useParams } from 'react-router-dom';
import { blogData } from '../../../constants/blogArticles';

function BlogDetailsPage() {
  const { id } = useParams();
  const currentPost = blogData.find((post) => post.id === +id);

  return (
    <>
      {/*<Breadcrumb name="Blog Details" title={currentPost.title} />*/}
      <BlogDetailsWrap currentPost={currentPost} />
    </>
  );
}

export default BlogDetailsPage;
